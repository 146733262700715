const React = require('react');

const Router = require('./Router');

import "prismjs/themes/prism-tomorrow.css";

const dataHelper = require('../dataHelper');
const schemaHelper = require('../schemaHelper');
const productHelper = require('../productHelper');
const listHelper = require('../listHelper');
const Connector = require('../connector');

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nodes: [],
      allNodes: [],
      domains: [],
      schemas: [],
      schema: null,
      products: [],
      product: 'all',
      options: { format: 'js', fullTextSearch: true },
      dialog: '',
      filters: [],
      hasMTR: false,
      internal: false,
      searchWord: '',
      notificaction: {},
      connection: 'not-connected',
      login: {},
      installExtension: '',
    };
    this.actions = {
      loadSchema: this.loadSchema.bind(this),
      setProduct: this.setProduct.bind(this),
      setOption: this.setOption.bind(this),
      showDialog: this.showDialog.bind(this),
      setSearchWord: this.setSearchWord.bind(this),
      setNotification: this.setNotification.bind(this),
      hideNotification: this.hideNotification.bind(this),
      connect: this.connect.bind(this),
      installExtension: this.showInstallExtension.bind(this),
    };
    this.connector = null;

    window.actions = this.actions; // for debugging from console
    window.app = this;
  }

  componentDidMount() {
    this.getSchemas();
    this.connector = new Connector();
    this.connector.setErrorHandler(() => this.setState({ connection: 'failed' }));
    this.applyFiltersFromUrl(location.search);
    this.restorePrefs();
    this.autoConnect();
    this.checkDevMode();
  }

  autoConnect() {
    this.connector.autoConnect()
    .then(() => {
      console.log('connected!');
      this.setState({ connection: 'connected' });
    })
    .catch(() => {
      console.log('not connecting');
    });
  }

  connect(login) {
    if (!login) {
      this.connector.disconnect();
      this.showDialog(false);
      this.setState({ connection: 'not-connected' });
    }
    else {
      this.setState({ connection: 'connecting', login });
      this.connector.connect(login)
        .then(() => {
          this.saveUserPref('login', login);
          this.setState({ connection: 'connected' });
          this.showDialog(false);
        });
      }
  }

  checkDevMode() {
    const hasChecked = sessionStorage.getItem('hasCheckedDevMode');
    if (location.hostname === 'localhost' && !hasChecked) {
      sessionStorage.setItem('hasCheckedDevMode', 1);
      if(confirm('Localhost detected. Run in local dev mode?')) {
        sessionStorage.setItem('devmode', 1);
        window.location.reload();
      }
    }
  }

  restorePrefs() {
    const prefs = this.getUserPrefs();
    if (prefs.login) {
      this.setState({ login: prefs.login });
    }
  }

  getUserPrefs() {
    const prefs = localStorage.getItem('prefs');
    return prefs ? JSON.parse(prefs) : {};
  }

  saveUserPref(name, value) {
    const prefs = this.getUserPrefs();
    prefs[name] = value;
    localStorage.setItem('prefs', JSON.stringify(prefs));
  }

  getSchemas() {
    const requested = new URLSearchParams(location.search).get('schema');

    dataHelper.fetchSchemas()
      .then((list) => {
        const schemas = schemaHelper.sortSchemas(list);
        this.setState({ schemas })
        const schema = requested ? schemas.find(s => s.name === requested) : schemas[0];

        if (!schema) {
          alert('Not able to find the requested xAPI schema.');
        }
        else {
          this.loadSchema(schema.name);
        }
      })
      .catch((e) => {
        console.warn('Failed to read schema');
        console.debug(e);
        this.setNotification('Not able to load schema. Are you offline?', 'error');
      });
  }

  setNotification(text, type = 'info') {
    this.setState({ notification: { type, text }});
  }

  hideNotification() {
    this.setState( { notification: {} });
  }

  loadSchema(name) {
    const schema = this.state.schemas.find(s => s.name === name);
    if (!schema) {
      alert('Unable to find schema: ' + name);
      return;
    }

    this.setNotification('Fetching xAPI schema...');

    dataHelper.fetchSchema(schema.url)
      .then((data) => {
        const nodes = data.objects;
        const products = schemaHelper.findProducts(nodes);
        const internal = schemaHelper.containsInternal(nodes);
        const hasMTR = schemaHelper.containsMTR(nodes);
        this.setState({ schema: name, allNodes: nodes, products, internal, hasMTR });
        this.setProduct(this.state.product);
        this.setFilterDefinition();
      })
      .catch((e) => {
        console.error('Failed to read', schema.url, e);
        alert('Not able to read schema file: ' + name);
      })
      .finally(() => this.hideNotification());
  }

  setFilterDefinition() {
    const { products, schemas, internal, hasMTR } = this.state;
    const schemaNames = schemas.map(s => s.name);
    const productNames = products.map(p => ({ key: p, name: productHelper.productName(p) }))
      .sort((p1, p2) => p1.name < p2.name ? -1 : 1);
    productNames.unshift({ key: 'all', name: 'Any product' });
    const list = values => values.map(v => ({ key: v, name: v }));

    const filters = [];
    filters.push({ name: 'Schema', values: list(schemaNames) });
    filters.push({ name: 'Product', values: productNames });
    filters.push({ name: 'Type', values: listHelper.types });
    if (internal) {
      filters.push({ name: 'Visibility', values: listHelper.visibilities });
    }
    if (hasMTR) {
      filters.push({ name: 'MTR', values: [true, false] });
    }
    filters.push({ name: 'User', values: listHelper.users });
    filters.push({ name: 'Deployment', values: listHelper.backends });

    this.setState({ filters });
  }

  setProduct(product) {
    const { allNodes } = this.state;
    const nodes = schemaHelper.findProductNodes(allNodes, product);
    const domains = schemaHelper.findDomains(nodes);
    this.setState({ nodes, product, domains });
  }

  setOption(name, value) {
    // console.log('set', name, value);
    if (name === 'Schema') {
      this.loadSchema(value);
    }
    else if (name === 'Product') {
      this.setProduct(value);
    }
    else {
      const options = Object.assign(this.state.options, { [name]: value });
      this.setState({ options });
    }
  }

  applyFiltersFromUrl(url) {
    const params = new URLSearchParams(url);
    if (params.has('search')) {
      this.setSearchWord(params.get('search'));
    }
    const options = ['Type', 'User', 'Product', 'Deployment', 'MTR'];
    options.forEach(o => {
      if (params.has(o)) this.setOption(o, params.get(o));
    })
  }

  setSearchWord(searchWord) {
    this.setState({ searchWord });
  }

  showInstallExtension(name) {
    this.setState({ installExtension: name });
    this.showDialog('install');
  }

  showDialog(dialog) {
    // closing connectAndInstall dialog => open install dialog automatically:
    if (!dialog && this.state.dialog === 'connectAndInstall') {
      this.setState({ dialog: 'install' });
    }
    else {
      this.setState({ dialog });
    }
  }

  render() {
    return <Router {...this.state} actions={this.actions} connector={this.connector} />;
  }
}

module.exports = App;
