const React = require('react');
const { useHistory } = require('react-router-dom');

const TypeLabel = require('../widgets/TypeLabel');
const CommandParams = require('./CommandParams');
const ValueSpace = require('./ValueSpace');
const EventProps = require('./EventProps');
const CodePage = require('./CodePage');
const PropTable = require('./PropTable');
const Icon = require('../widgets/Icon');
const { simplifyNames } = require('../../productHelper');


const VersionChooser = (props) => {
  const { versions, current } = props;

  const options = versions.map((v) => {
    // const names = v.products.map(p => productName(p)).join(', ');
    const prods = simplifyNames(v.products);
    const productNames = prods.length > 3
      ? prods.slice(0, 3).join(', ') + ' +' + (prods.length - 3)
      : prods.join(', ');
    const id = v.products[0];

    return (
      <option key={id} value={id}>{productNames}</option>
    );
  });

  const history = useHistory();

  const loadVersion = (e) => {
    const prod = e.target.value;
    const url = location.pathname + '?p=' + prod;
    history.push(url);
  }

  const select = (
    <select value={current} onChange={loadVersion}>{options}</select>
  );

  return (
    <div>Other products: {select}</div>
  );
};

const ExpandableText = (props) => {
  const [showAll, setShowAll] = React.useState(false);
  const { text, maxChars } = props;

  if (text && text.length > maxChars + 100) {
    if (!showAll) {
      const short = text.slice(0, maxChars) + '...';
      return <div onClick={() => setShowAll(true)}>{short}<br/>Read more...</div>;
    }
    else {
      return <div onClick={() => setShowAll(false)}>{text}<br/>Read less...</div>
    }
  }

  return (
    <>{text}</>
  );
};




const DetailsView = (props) => {
  const { node, nodes, options, setOption, internal } = props;
  if (!node) {
    return <span>Node not found</span>;
  }
  const { type, path, attributes, products } = node;
  const { access, backend, role, description, params, multiline, valuespace, privacyimpact } = attributes;

  console.debug('Show node:', node);

  let specific;
  if (type === 'Command') {
    specific = <CommandParams params={params} multiline={multiline} />;
  }
  else if (type === 'Configuration' || type === 'Status') {
    specific = <ValueSpace {...node} />;
  }
  else if (type === 'Event') {
    specific = <EventProps {...node} />;
  }

  const productNames = simplifyNames(products).join(', ');

  const hasMtr = attributes.include_for_extension === 'mtr';

  const accessNames = {
    'public-api': 'Public',
    'public-api-preview': 'Preview',
    'internal': 'Internal',
  };
  const backends = { any: 'Any', cloud: 'Cloud', onprem: 'On-prem' };
  const commons = [];
  if (internal) {
    commons.push({ name: 'Access', value: accessNames[access] || access });
  }
  commons.push({ name: 'Back-end', value: backends[backend] });
  commons.push({ name: 'User roles', value: role.join(', ') });
  commons.push({ name: 'Products', value: productNames });
  if (privacyimpact) {
    commons.push({ name: 'Privacy impacting', value: privacyimpact === 'True' ? '⚠️ Yes' : 'No'})
  }

  let inMtr = hasMtr ? 'Yes' : 'No'
  if (type === 'Event') {
    inMtr = 'Yes (if feature is supported)'
  }

  commons.push({ name: 'Microsoft Teams Rooms (MTR)', value: inMtr });

  const common = <PropTable rows={commons} />;

  const versions = nodes.filter(n => n.path === node.path && n.type === node.type);
  const versionChooser = versions.length > 1
    && <VersionChooser versions={versions} current={node.products[0]} />;

  const setFormat = f => setOption('format', f);
  const url = location.href.split('?').shift(); // remove search part
  const markdownLink = `[x${type} ${path}](${url})`;
  const copyLink = () => navigator.clipboard.writeText(markdownLink);

  return (
    <div className="node-details">

      <div className="api-details">
        <div className="name">
          <TypeLabel type={type} />
          {path}
          &nbsp;
          <a onClick={copyLink} className="link" title="Copy a markdown-friendly link (for Webex Teams etc)">
            <Icon type="copy" />
          </a>
        </div>
        <div className="info">
          <ExpandableText text={description} maxChars={400} />
        </div>
        {specific}
        <hr/>
        {common}
        {versionChooser}
        <textarea id="copy-paste" />
      </div>
      <hr/>
      <CodePage node={node} format={options.format} setFormat={setFormat} />
    </div>
  );
};

module.exports = DetailsView;
